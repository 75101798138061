// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
// var countUpStats = false;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see ttps://fresco-docs.netlify.com/#/carousel/background-video

var countUpStatsDuration = 3000;
var countUpStats = true;

// Quick exit button https://raisingit.atlassian.net/browse/CR-279
$('body').append('<a class="button quick-exit" href="https://www.google.co.uk/">Quick Exit</a>');